define('minerva-frontend/components/lob-forms/m-helper-proposal/component', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Component.extend({
    isMageiras: Ember.computed.equal('_changeset.helperOccupation.id', 'MAGEIRAS'),
    isErgatis: Ember.computed.equal('_changeset.helperOccupation.id', 'GEORGIKOS_ERGATIS'),
    isHelperAddressOfEmploymentVisible: Ember.computed.or('isMageiras', 'isErgatis'),
    genders: Ember.computed.alias('params.genders'),
    medicalCoverTypes: Ember.computed.filter('params.medical-cover-types', function (coverType, index, array) {
      return !['VISITOR', 'IN_PATIENT', 'MORTAL_REMAINS_TRANSPORT', 'MORTAL_REMAINS_TRANSPORT_B'].includes(coverType.id);
    }),
    actions: {
      emptySubmit: function emptySubmit() {},
      submit: function submit(model, submissionStatus) {
        //Here we should handle Lob specific logic. When done, pass the stuff on to the form-wrapper to submit the info!
        this.onSubmit(model, submissionStatus);
      }
    }
  });
});